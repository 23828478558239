import { Navigate, useRoutes } from 'react-router-dom';
import MainLayout from 'src/components/MainLayout';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import PriceList from './pages/pricelist';

import {
  BuildsGalleryList,
  BuildsGalleryDetails
} from './pages/gallery-builds';
import ScrollToTop from './components/util/ScrollToTop';

const routes = [
  {
    path: '',
    element: <MainLayout />,
    children: [
      { path: 'pricelist', element: <PriceList /> },

      { path: 'builds-gallery', element: <BuildsGalleryList /> },
      {
        path: 'builds-gallery/details/:uuid',
        exact: true,
        element: <BuildsGalleryDetails />
      },

      { path: '', element: <Home /> },

      { path: '404', element: <NotFound /> },

      { path: '*', element: <Navigate to="404" /> }
    ]
  }
];

const RoutesWithScroll = () => {
  const routing = useRoutes(routes);
  ScrollToTop();

  return <div>{routing}</div>;
};

export default RoutesWithScroll;
