import { useEffect } from 'react';

const PriceListView = () => {
  useEffect(() => {
    window.location.replace('https://pcworth.com/pricelist');
  }, []);

  return null;
};

export default PriceListView;
