import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const component = () => {
  const { uuid } = useParams();

  useEffect(() => {
    window.location.replace(
      `https://pcworth.com/builds-gallery/details/${uuid}`
    );
  }, []);

  return null;
};

export const BuildsGalleryDetails = React.memo(component);
