import { createMuiTheme, colors, responsiveFontSizes } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import shadows from './shadows';
import typography from './typography';
import Colors from './colors';

const breakpoints = createBreakpoints({});

const muiTheme = createMuiTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      main: '#ffffff'
    },
    secondary: {
      main: '#CF151A'
    },
    text: {
      primary: '#172b4d',
      secondary: '#828282'
    }
  },
  shadows,
  typography,
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'bgGreySection' },
          style: {
            fontFamily: 'Roboto',
            color: Colors.white,
            opacity: 0.05,
            letterSpacing: 10,
            fontWeight: 900,
            fontSize: 140,
            [breakpoints.down('sm')]: {
              fontSize: 90
            }
          }
        },
        {
          props: { variant: 'captionBold' },
          style: {
            fontFamily: 'Roboto',
            color: Colors.white,
            fontWeight: 700,
            fontSize: 10,
            [breakpoints.down('sm')]: {
              fontSize: 8
            }
          }
        },
        {
          props: { variant: 'priceList' },
          style: {
            fontFamily: 'Roboto',
            color: Colors.black,
            fontWeight: 700,
            fontSize: 17,
            [breakpoints.down('md')]: {
              fontSize: 15
            },
            [breakpoints.down('sm')]: {
              fontSize: 13
            }
          }
        }
      ]
    }
  }
});

const theme = responsiveFontSizes(muiTheme);

export default theme;
