import React, { useEffect } from 'react';

const component = () => {
  useEffect(() => {
    window.location.replace('https://pcworth.com/builds-gallery');
  }, []);

  return null;
};

export const BuildsGalleryList = React.memo(component);
