import { Helmet } from 'react-helmet';
import { Box, Container, Typography } from '@material-ui/core';

const NotFound = () => (
  <>
    <Helmet>
      <title>404 | Not Found</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center'
      }}
    >
      <Container maxWidth="md">
        <Typography align="center" color="textPrimary" variant="h1">
          404: The page you are looking for isn’t here
        </Typography>
        <Typography align="center" color="textPrimary" variant="subtitle2">
          You either tried some shady route or you came here by mistake.
          Whichever it is, try using the navigation
        </Typography>
        <Box sx={{ textAlign: 'center' }}>
          <img
            alt="Under development"
            src="/static/images/undraw_page_not_found_su7k.svg"
            style={{
              marginTop: 50,
              display: 'inline-block',
              maxWidth: '100%',
              width: 560
            }}
          />
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <a
            href="https://pcworth.com"
            target="_blank"
            rel="noreferrer noopenner"
          >
            <Typography
              sx={{
                cursor: 'pointer',
                textDecoration: 'underline'
              }}
              variant="h6"
              color="#0C304A"
            >
              https://pcworth.com
            </Typography>
          </a>
          <Typography color="#0C304A">
            (Click the link above to redirect to pcworth.com)
          </Typography>
        </Box>
      </Container>
    </Box>
  </>
);

export default NotFound;
