import { useEffect } from 'react';

const Home = () => {
  useEffect(() => {
    window.location.replace('https://pcworth.com');
  }, []);

  return null;
};

export default Home;
