import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import Routes from 'src/routes';

const generateClassName = createGenerateClassName({
  seed: 'app1'
});

const App = () => {
  return (
    <StylesProvider injectFirst generateClassName={generateClassName}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Routes />
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
