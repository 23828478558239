import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@material-ui/core';
// ===============================================================

const component = ({ redirectionLink, timeLeft }) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        minHeight: '65vh'
      }}
    >
      <Typography variant="h1" marginTop={10} color="#0C304A">
        WELCOME TO PC WORTH
      </Typography>
      <img
        width={200}
        height={200}
        src="/static/images/kachi/kachi-happy.png"
        alt="KachiHappy"
      />
      <Typography m={1} variant="h1" color="#0C304A">
        LOADING...
      </Typography>
      <Typography m={1} variant="h5" color="#0C304A">
        We would like to know you that our new website PC worth is ready to use.
        You will be redirected to our new website PC builds details
      </Typography>
      <Typography m={1} color="#0C304A">
        You will be redirected:
        <span>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          {timeLeft ? `in ${timeLeft} second(s)` : 'to'}
        </span>
      </Typography>
      <a href={redirectionLink} target="_blank" rel="noreferrer noopenner">
        <Typography
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
          variant="h6"
          color="#0C304A"
        >
          {redirectionLink}
        </Typography>
      </a>
      <Typography color="#0C304A">
        (If you are not redirected click the link above)
      </Typography>
    </Box>
  );
};

export const ToBeRedirected = React.memo(component);
export default ToBeRedirected;

ToBeRedirected.propTypes = {
  redirectionLink: PropTypes.string,
  timeLeft: PropTypes.string
};
