import { colors as MUIColors } from '@material-ui/core';

export default {
  secondary: '#CF151A',
  backgroundGrey: '#292929',
  white: '#FAFAFA',
  text: '#000000',
  ...MUIColors,
  primary: '#0C304A',
  warning: 'orange',
  error: '#f44336',
  success: 'green',
  skyBlue: '#87ceeb',
  normal: '#263238',
  black: '#000000'
};
