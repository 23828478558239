import { Outlet } from 'react-router-dom';
import React from 'react';
import { experimentalStyled } from '@material-ui/core';

const MainLayoutRoot = experimentalStyled('div')(
  ({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  })
);

const MainLayoutWrapper = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  // paddingTop: 90
});

const MainLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const MainLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto'
});

const MainLayout = () => {
  return (
    <div>
      {/* <SwipeableTemporaryDrawer ref={drawerRef} /> */}
      <MainLayoutRoot>
        {/* <MainNavbar onMobileNavOpen={() => drawerRef.current.toggleDrawerUsingRef()} /> */}
        <MainLayoutWrapper>
          <MainLayoutContainer>
            <MainLayoutContent>
              <Outlet />
            </MainLayoutContent>
          </MainLayoutContainer>
        </MainLayoutWrapper>
      </MainLayoutRoot>
    </div>
  );
};

export default MainLayout;
